<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* 全局样式可以保留在这里 */
</style>
